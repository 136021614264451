import React from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Section, Video, Flex, Image } from 'ui-kit'
import styles from './MassMedia.module.scss'

const MassMedia = ({ observer }) => {
  const { t } = useTranslation()

  return (
    <Section id="mass-media" observer={observer}>
      <h2>{t('landing.sectionsTitle.massMedia')}</h2>
      <Flex alignContent="center" className={styles.massMedia} justifyContent="between" wrap>
        <Video src="https://www.youtube.com/embed/upYB3yRuBNs" title={t('landing.sectionsText.massMedia.1')}/>
        <Video src="https://www.youtube.com/embed/PVimF0vKrcY" title={t('landing.sectionsText.massMedia.2')}/>
        <Video src="https://www.youtube.com/embed/ANd1RM5pwM8" title={t('landing.sectionsText.massMedia.3')}/>
        <Video src="https://www.youtube.com/embed/rrYaWveO1uw" title={t('landing.sectionsText.massMedia.7')}/>
        <Video src="https://www.youtube.com/embed/Nv8DQO1thCI" title={t('landing.sectionsText.massMedia.8')}/>
        <a
          /* eslint-disable-next-line max-len */
          href="https://progroshi.news/news/ekonomika/ryatunok-koriv-z-borodyanki-i-shchastya-ditey-z-mariupolya-vidverta-rozpovid-volonterki-pro-viynu-900.html"
          rel="noreferrer"
          target="_blank"
        >
          <Image
            alt={t('landing.sectionsText.massMedia.4')}
            src="https://assets.armiyavolonteriv.com/production/mass-media/1.png"
          />
        </a>
        <a
          /* eslint-disable-next-line max-len */
          href="https://www.badische-zeitung.de/die-front-hinter-der-front-ukrainische-ehrenamtliche-helfen-ueberall--232896378.html"
          rel="noreferrer"
          target="_blank"
        >
          <Image
            alt={t('landing.sectionsText.massMedia.5')}
            src="https://assets.armiyavolonteriv.com/production/mass-media/2.jpg"
            style={{ objectFit: 'cover' }}
          />
        </a>
        <a
          /* eslint-disable-next-line max-len */
          href="https://www.the-village.com.ua/village/city/asking-question/327301-kudi-peredati-rechi-dlya-vpo"
          rel="noreferrer"
          target="_blank"
        >
          <Image
            alt={t('landing.sectionsText.massMedia.6')}
            src="https://assets.armiyavolonteriv.com/production/mass-media/3.jpg"
            style={{ objectFit: 'cover' }}
          />
        </a>
        <a
          href="https://vechirniy.kyiv.ua/news/84964/"
          rel="noreferrer"
          target="_blank"
        >
          <Image
            alt={t('landing.sectionsText.massMedia.10')}
            src="https://assets.armiyavolonteriv.com/production/mass-media/5.webp"
            style={{ objectFit: 'cover' }}
          />
        </a>
        <a
          href="https://kvertus.ua/kvertus-provided-address-help-to-our-fighters"
          rel="noreferrer"
          target="_blank"
        >
          <Image
            alt={t('landing.sectionsText.massMedia.9')}
            src="https://assets.armiyavolonteriv.com/production/mass-media/4.webp"
            style={{ objectFit: 'cover' }}
          />
        </a>
        <a
          href="https://blagoukraine.org/news/kyyivshhyna-2023-2-2853.html"
          rel="noreferrer"
          target="_blank"
        >
          <Image
            alt={t('landing.sectionsText.massMedia.11')}
            src="https://assets.armiyavolonteriv.com/production/mass-media/6.webp"
            style={{ objectFit: 'cover' }}
          />
        </a>
      </Flex>
    </Section>
  )
}

MassMedia.propTypes = {
  observer: PT.func.isRequired,
}

export default MassMedia
