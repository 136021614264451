import { snakeCase } from 'lodash'

export const parseSortString = (sortString) => {
  if (!sortString) {
    return ['', '']
  } else {
    return sortString.split('.')
  }
}

export const makeSortString = (currentSortString, by) => {
  const [currBy, currDir] = parseSortString(currentSortString)
  const parsedBy = snakeCase(by || '')
  if (currBy === parsedBy) {
    const dir = currDir === 'asc' ? 'desc' : 'asc'
    return `${parsedBy}.${dir}`
  } else {
    return `${parsedBy}.asc`
  }
}
