import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { LandingDataContext } from 'contexts'
import MetricCard from '../components/MetricCard'
import { Section, Flex } from 'ui-kit'
import { FOUNDING_DATE, CHARITY_TRIPS } from 'common/constants/landing'
import styles from './Metrics.module.scss'

const Metrics = () => {
  const { t } = useTranslation()
  const { closedRequests } = useContext(LandingDataContext)

  const calculateMonthsWork = () => {
    const start = DateTime.fromISO(FOUNDING_DATE)
    const diffMonths = DateTime.now().diff(start, 'months').toObject().months

    return Math.floor(diffMonths)
  }

  const calculatePeopleMetric = () => Math.floor(closedRequests * 3.645)

  const monthsWork = useMemo(calculateMonthsWork, [])
  const peopleMetric = useMemo(calculatePeopleMetric, [closedRequests])

  return (
    <Section className={styles.metrics} id="metrics" noContainer>
      <Flex className="container" column>
        <h2>{t('landing.sectionsTitle.metrics')}</h2>
        <div className={styles.container}>
          <MetricCard number={monthsWork} title={t('landing.sectionsText.metrics.monthsWork')} />
          <MetricCard number={closedRequests} title={t('landing.sectionsText.metrics.closedRequests')} />
          <MetricCard number={CHARITY_TRIPS} title={t('landing.sectionsText.metrics.charityTrips')} />
          <MetricCard number={peopleMetric} title={t('landing.sectionsText.metrics.people')} />
        </div>
      </Flex>
    </Section>
  )
}

export default Metrics
